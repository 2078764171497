import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const WeekendPage = () => (
  <Layout>
    <SEO title=" Weekend w Trójmieście" />
    <section className="single-trip-description">
      <div className="wrap">
        <div className="negative-margin-left negative-margin-photo">
          <img src={`/static/weekend-w-trojmiescie.jpg`} alt="" />
        </div>
        <div>
          <h3>ODKRYJ PIĘKNO PÓŁNOCNEJ POLSKI</h3>
          <h1> Weekend w Trójmieście!</h1>
          <p>
            Trójmiasto to idealny kierunek na weekendowy wypad. Miasta nie
            zawiodą Was pod względem ilości ciekawych miejsc do zobaczenia.
            Długa historia Gdańska pozostawiła po sobie wiele zabytków, które
            zachwycają architekturą i pięknem. Ulice pełne są kawiarni, butików
            z bursztynami oraz muzeów. Standard restauracji i lokalnych browarów
            pozytywnie pobudzi Wasze kubki smakowe. Kultowym miejscem jest także
            Sopot. To niezwykły nadmorski kurort z piaszczystą plażą i
            najdłuższym drewnianym molo nad Bałtykiem. Zapraszamy także do Gdyni
            – najmłodszego miasta Trójmiasta. Ostatniego dnia jest możliwość
            zobaczenia Gdańska z perspektywy wody.
          </p>
        </div>
        <div className="trip-plan">
          <h4>W programie proponujemy:</h4>
          <div className="day">
            <div className="day-number">Dzień 1</div>
            <div className="day-plan">
              <ul>
                <li>Transfer z lotniska / dworca PKP</li>
                <li>Zakwaterowanie w hotelu / pensjonacie / apartamencie</li>
                <li>
                  Spacer z przewodnikiem Drogą Królewską oraz pokaz szlifowania
                  bursztynu
                </li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 2</div>
            <div className="day-plan">
              <ul>
                <li>Zwiedzanie Katedry w Oliwie – koncert organowy</li>
                <li>Zwiedzanie Sopotu i Gdyni</li>
                <li>
                  Przerwa na lunch w rybackiej restauracji nad Zatoką Gdańską
                </li>
                <li>
                  Wieczorem degustacja piwa w restauracji przy przystani
                  jachtowej
                </li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 3</div>
            <div className="day-plan">
              <ul>
                <li>
                  Wycieczka statkiem stylizowanym na XVII w. – na pokładzie jest
                  bar i możliwość wysłuchania koncertu szantowego na żywo
                </li>
                <li>Transfer na lotnisko / dworzec PKP</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <ContactForm />
        </div>
        <div className=" prices">
          <div>
            CENA OD: 450 zł / za osobę
            <p> CENA ZAWIERA</p>
            <ul>
              <li>Transfer </li>
              <li>Zakwaterowanie </li>
              <li>Towarzystwo przewodnika turystycznego </li>
            </ul>
          </div>
          <div>
            Cena zależy od ilości osób, programu i standardu noclegu. Cenę
            kalkulujemy dla każdego klienta indywidualnie, ponieważ zależy ona
            od ilości osób, programu i rodzaju noclegu. Każdy program
            dostosowany jest do życzeń naszych gości oraz do ich harmonogramu
            podróży.
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default WeekendPage
